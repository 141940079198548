<template>
	<div style="background: #fff;padding: 15px;">
		<div class="filter-container">
			<div class="filter-item">
				<div style="display: flex;">
					<div class="label" style="width: 70px;text-align: right;white-space: nowrap;font-weight: 700;">关键字:</div>
					<el-select v-model="keywordsType" @change="keywordsTypeChange" placeholder="请选择" style="margin-right: 20px;width: 120px;">
						<el-option :key="0" label="单品" :value="0"></el-option>
						<el-option :key="1" label="组合商品" :value="1"></el-option>
					</el-select>
					<el-input v-model="configSearchKey" style="width: 200px;" placeholder="商品名称、编码、规格条形码"></el-input>
					<div class="label" style="white-space: nowrap;font-weight: 700;margin-left: 20px;">销售类型: </div>
					<el-select v-model="saleType" @change="saleTypeChange" placeholder="请选择" style="margin-right: 20px;width: 150px;">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option :key="0" label="单品销售" :value="0"></el-option>
						<el-option :key="1" label="组合销售" :value="1"></el-option>
					</el-select>
					<label class="label" style="white-space: nowrap;">商品分组: </label>
					<el-select v-model="groupId" placeholder="请选择" filterable clearable style="margin-right: 20px;width: 150px;">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option v-for="(item,index) in groupList" :key="index" :label="item.GroupName" :value="item.Id">
						</el-option>
					</el-select>
				</div>
				<div style="display: flex;margin-top: 10px;">
					<div class="label" style="width: 70px;white-space: nowrap;font-weight: 700;">商品品牌: </div>
					<el-select v-model="brandId" placeholder="请选择" filterable clearable style="margin-right: 20px;width: 150px;">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option v-for="(item,index) in brandList" :key="index" :label="item.BrandName" :value="item.Id">
						</el-option>
					</el-select>
					<label class="label">配送方式: </label>
					<el-select v-model="seedType" placeholder="请选择" filterable clearable style="margin-right: 20px;">
						<el-option :key="-1" label="全部" :value="null"></el-option>
						<el-option :key="0" label="快递发货" :value="0"></el-option>
						<el-option :key="1" label="到店自提" :value="1"></el-option>
						<el-option :key="2" label="同城配送" :value="2"></el-option>
						<el-option :key="3" label="仅快递发货" :value="3"></el-option>
						<el-option :key="4" label="仅到店自提" :value="4"></el-option>
						<el-option :key="5" label="仅同城配送" :value="5"></el-option>
					</el-select>
					<div>
						<el-button type="primary" style="width:90px;" size="small" @click="goodsFilter">查询</el-button>
					</div>
				</div>
			</div>
		</div>

		<div class="table-container" style="margin-top: 20px;">
			<el-table :data="productList" @sort-change="sortChange" style="width: 100%;" ref="doorChose" v-loading="loading"
			 @selection-change="productSelectChange" @cell-mouse-enter="cellMouse" @cell-mouse-leave="rowIndex=0">
				<el-table-column type="selection" width="50">
				</el-table-column>
				<el-table-column label="商品" :key='9' width="400px">
					<template slot-scope="scope">
						<div class="product-info">
							<img v-if="scope.row.ImgUrl " :src="imgUrl + scope.row.ImgUrl">
							<svg-icon v-else icon-class="noImgIcon" />
							<div>
								<div style="display:flex;justify-content:space-between;align-items:center;">
									<div style="width: 300px;margin-right: 5px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;">
										<span style="color: #F56C6C;font-size: 12px;" v-if="scope.row.ProductType === 1">[组合]</span>

										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>

									</div>
								</div>
								<div v-if="scope.row.ProductNo">{{scope.row.ProductNo}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column sortable="custom" prop="Price" label="价格" :key='10'>
					<template slot-scope='scope'>
						<div style="color: #F56C6C;">{{scope.row.Price}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="Stock" sortable="custom" label="库存" :key='11' class-name="columnClass">
					<template slot-scope="scope" slot="header">
						<div class = "flexRow">
							<span>库存</span>
							<el-popover	placement="top" trigger="hover">
								<div style="max-width:400px">
									<span>门店库存可设置共享商城库存模式或独立门店库存模式；当前为</span>
									<span style="color:#f56c6c">{{ShopProductStockModel==0?'共享商城库存模式':'门店独立库存模式'}}</span>
									<span>，可前往商城设置中心修改模式
										<br>
										独立门店库存模式下，可在此处管理商品在门店的库存</span>
								</div>
								<i class = "el-icon-question" style="margin-left:5px" slot="reference"></i>
							</el-popover>
						</div>
					</template>
					<template slot-scope="scope">
						<div class="flexRow">{{scope.row.Stock}}
							<!--共享商城库存模式  组合销售 隐藏编辑-->
							<div class = "hoverShowIcon" :style="{display:rowIndex==scope.row.Id?'block':'none'}"
							 v-if="ShopProductStockModel!=0&&scope.row.ProductType!=1">
								<buttonPermissions :datas="'doorGoodsEditInventory'">
									<i class = "el-icon-edit-outline" style="margin-left:5px;cursor: pointer" @click="forThe(scope.row)"></i>
								</buttonPermissions>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间" :key='12'></el-table-column>
				<el-table-column label="快递发货">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<!-- <span @click="showTip" v-if="scope.row.ProductType === 1">
								<el-switch style="display: block" :value="true" active-color="#53A8FF" inactive-color="#DCDFE6">
								</el-switch>
							</span> -->
							 <!-- v-else -->
							<el-switch style="display: block" @change="seedTypeChange(scope.row, 0)" v-model="scope.row.IsOpenExpress"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="到店自提">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<!-- <span @click="showTip" v-if="scope.row.ProductType === 1">
								<el-switch v-if="scope.row.ProductType === 1" @click="showTip" style="display: block" :value="false"
								 active-color="#53A8FF" inactive-color="#DCDFE6">
								</el-switch>
							</span> -->
							<!-- v-else -->
							<el-switch style="display: block" @change="seedTypeChange(scope.row, 1)" v-model="scope.row.IsOpenSelfExtract"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="同城配送">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block" @change="seedTypeChange(scope.row, 2)" v-model="scope.row.IsOpenInCityDelivery"
							 active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="90">
					<template slot-scope="scope">
						<el-button type="text" @click="eidtPrice(scope.row)">供货价</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="display:flex;justify-content:space-between;align-items:center;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					<el-checkbox @change="tabCheckedChange" v-model="checked"></el-checkbox>

					<span @click="handleCommand" style="margin-left: 10px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;">批量设置配送方式</span>

					<!-- <span style="margin-left: 20px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;" @click="batchSettingPrice">批量设置供货价</span> -->
					<!-- <span style="margin-left: 10px;border: 1px solid #E5E9F2;padding: 5px 10px;cursor: pointer;" @click="batchSettingEmail"
					 v-if="productInfo.ProductType===0">批量设置配送方式</span> -->
				</div>
				<el-pagination v-if="configTotal" style="margin-top:20px;float:right;" @size-change="handleConfigSizeChange"
				 @current-change="handleConfigPageChange" :current-page="configPageIndex" :page-sizes="[10, 20, 30, 40, 50]"
				 :page-size="configPageSize" layout="total, sizes, prev, pager, next, jumper" :total="configTotal">
				</el-pagination>
			</div>
		</div>

		<!-- 提示弹窗 -->
		<el-dialog :visible.sync="stopDialogShow" width="400px" center :show-close="false">
			<div style="line-height: 25px;">您正在修改{{multipleSelection.length}}个商品{{emailVisble?'配送方式':'供货价'}}，是否确认继续修改？</div>
			<span slot="footer" class="dialog-footer">
				<el-button style="width: 120px;" @click="stopActive(0)">取 消</el-button>
				<el-button style="width: 120px;margin-left: 30px;" type="primary" :loading="loading" @click="stopActive(1)">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 商品供货价编辑的弹框 -->
		<el-dialog :visible.sync="stockVisble" width="550px" title="设置供货价" :before-close="Clearmulation">
			<div style="margin-bottom: 20px;border-top: 1px solid #ccc;padding-top: 20px;">商品:<span style="color: #F56C6C;" v-if="ProductType === 1">[组合]</span>{{productInfo.Name}}</div>
			<el-table :data="specList" style="width: 100%;" v-if="!isNormalSpec">
				<el-table-column prop="SpecValue" :label="SpecTitle" width="100px"></el-table-column>
				<el-table-column prop="SpecValue2" :label="SpecTitle2" width="100px"></el-table-column>
				<el-table-column label="供货价">
					<template slot-scope="scope">
						<el-input v-model="scope.row.SupplyPrice" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
					</template>
				</el-table-column>
			</el-table>

			<el-form :model="ruleForm" ref="ruleForm" v-if="isNormalSpec" :rules="rules">
				<el-form-item label="供货价:" prop="singleprice">
					<el-input v-model="ruleForm.singleprice" style="width: 400px;" size="mini" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top:30px;">
				<el-button type="primary" style="width: 120px;" @click='savePriceChange' :loading="loading">保存</el-button>
			</div>
		</el-dialog>

		<!-- 商品配送方式编辑的弹框 -->
		<el-dialog :visible.sync="emailVisble" width="550px" title="批量设置配送方式" @closed="emailData=[{email: false,self: false,city:false,}]">
			<!-- <div style="margin-bottom: 20px;border-top: 1px solid #ccc;padding-top: 20px;">商品:{{productInfo.Name}}</div> -->
			<el-table :data="emailData" style="width: 100%;">
				<el-table-column label="快递发货">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block;line-height: 8px;" v-model="scope.row.email" active-color="#53A8FF"
							 inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="到店自提">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block" v-model="scope.row.self" active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="同城配送">
					<template slot-scope='scope'>
						<div style="height: 22px;">
							<el-switch style="display: block" v-model="scope.row.city" active-color="#53A8FF" inactive-color="#DCDFE6">
							</el-switch>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="text-align: center;margin-top:30px;">
				<el-button type="primary" style="width: 120px;" @click='saveSeedType'>保存</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="forTheShow" v-loading="forTheLoading" title="商品出/入库" class="dialogpad" @closed="closeforTheShow">
			<div style="border-top:1px solid #cccccc"></div>
			<div>
				<el-form :model="forTheruform" :rules="forTherules" ref="forTheruform" label-width="70px">
					<div style="color:#606266;font-size:14px;margin:20px 0px">
						商品：{{forTheMsg.Name}}
					</div>
					<div v-if="!forTheruform.SpecTitle && !forTheruform.SpecTitle2">
						<div v-for="(v,i) in forTheruform.ProductSpecList" :key="i">
							<el-form-item label="库存：" :prop="'ProductSpecList.'+i+'.Stock'" :rules="forTherules.Stock" class="havestart">
								<el-input v-model="v.Stock" style="width:200px;"></el-input>
							</el-form-item>
						</div>
					</div>
					<div v-else>
						<el-table :data="forTheruform.ProductSpecList" max-height="500" border>
							<el-table-column prop="SpecValue" v-if="forTheruform.SpecTitle">
								<template slot-scope="scope" slot="header">
									{{forTheruform.SpecTitle}}
								</template>
							</el-table-column>
							<el-table-column prop="SpecValue2" v-if="forTheruform.SpecTitle2">
								<template slot-scope="scope" slot="header">
									{{forTheruform.SpecTitle2}}
								</template>
							</el-table-column>
							<el-table-column prop="SpecValue2" label="库存">
								<template slot-scope="scope">
									<el-form-item :prop="'ProductSpecList.'+scope.$index+'.Stock'" :rules="forTherules.Stock" :key="scope.$index" label-width="0px">
										<el-input v-model="scope.row.Stock" style="width:150px"></el-input>
									</el-form-item>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-form>
				<div style="margin-top:30px;text-align:center">
					<el-button type="primary" @click="scveStock('forTheruform')" :loading="forTheLoading">保存</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		shopproductconfigmanager,
		shopproductsendtypeedit,
		productbaseinfo,
		productspecinfo,
		setshopproductspecsupplyprice,
		shopproductsendtypebatchedit,
		shopshopProductSpecStock,
		shopshopProductSpecChangeStock
	} from '@/api/TurnTomySelf'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		productBrandList,
		productGroupList
	} from '@/api/goods.js'

	import config from '@/config/index'

	import {
		mapGetters
	} from 'vuex'

	export default {
		components:{
			buttonPermissions
		},
		data() {
			var checkStock = (rule, value, callback) => {
				// console.log(value)
        if (!String(value)) {
          callback(new Error('请完善库存信息'));
        } else if (value < 0) {
          callback(new Error('库存不可为负数'));
        } else if (value != parseInt(value)) {
          callback(new Error('仅可输入整数数值'));
        } else {
          callback();
        }
      };
			return {
				ShopProductStockModel:0,
				id: '',
				imgUrl: config.IMG_BASE,
				ruleForm: {
					name: '',
					code: '',
					singleprice: '',
					singledesc: ''
				},
				emailData: [{
					email: false,
					self: false,
					city:false,
				}],
				rules: {
					singleprice: [{
						required: true,
						message: '请输入供货价',
						trigger: 'blur'
					}],
				},
				stockData: [],

				loading: false,
				emailVisble: false,
				groupName: "",
				activeType: '',

				stopDialogShow: false,
				stockVisble: false, //是否显示供货价弹窗
				checkedAll: false, //当前页全选
				checked: false, //是否 仅看可选商品
				addProductDialogShow: false,
				isCurrentAll: false,
				imageUrl: 'http://pic39.nipic.com/20140307/13928177_195158772185_2.jpg',

				productInfo: {},
				specList: [],
				multipleSelection: [],

				configPageIndex: 1,
				configPageSize: 20,
				configTotal: 0,
				keywordsType: 0,
				configSearchKey: '',
				saleType: null,
				groupId: null,
				brandId: null,
				seedType: null,
				orderByType: '',
				orderByIsAsc: null,
				groupList: [],
				brandList: [],
				productList: [],
				isCurrentPage: false,
				ProductType: null,
				SpecTitle: '',
				SpecTitle2: '',

				forTheShow:false,
				forTheLoading:false,
				forTheMsg:{},
				forTheruform:{},
				forTherules:{
					Stock:[
						{ validator: checkStock, trigger: 'blur' }
					]
				},
				rowIndex:0,
			};
		},
		computed: {
			isNormalSpec() {
				if (this.specList.length && this.specList[0].SpecValue) {
					return false;
				} else {
					return true
				}
			},
			...mapGetters([
				'ComponentId'
			]),
		},
		beforeMount() {
			this.ShopProductStockModel = localStorage.getItem('ShopProductStockModel')
			this.id = this.$route.query.id;
			this.getList();
			this.getGroupList();
			this.getBrandList();
		},
		methods: {
			cellMouse(row, column, cell, event){
				this.rowIndex = row.Id
			},
			closeforTheShow(){
				this.$refs.forTheruform.clearValidate()
			},
			scveStock(formName){
				this.$refs[formName].validate((valid) => {
          if (valid) {
            this.toscveStock()
          } else {
            return false;
          }
        });
			},
			async toscveStock(){
				this.forTheLoading = true
				try{
					let data = {
						ShopId:this.$route.query.id,
						Id:this.forTheMsg.Id,
						ProductStockList:JSON.parse(JSON.stringify(this.forTheruform.ProductSpecList))
					}
					let res = await shopshopProductSpecChangeStock(data)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.forTheShow = false
						this.getList()
					}
				}finally{
					this.forTheLoading = false
				}
			},
			forThe(row){
				this.forTheShow = true
				this.forTheMsg = row
				// console.log(row)
				this.getinventory()
			},
			async getinventory(){
				this.forTheLoading = true
				try{
					let data = {
						ShopId:this.$route.query.id,
						Id:this.forTheMsg.Id
					}
					let res = await shopshopProductSpecStock(data)
					if(res.IsSuccess){
						this.forTheruform = res.Result
						// console.log(res.Result)
					}
				}finally{
					this.forTheLoading = false
				}
			},
			//清空
			Clearmulation() {
				this.stockVisble = false
				this.multipleSelection = []
			},
			async getList() {

				this.loading = true;

				const res = await shopproductconfigmanager({
					Id: this.id,
					KeyWordsType: this.keywordsType,
					KeyWords: this.configSearchKey,
					ProductType: this.saleType,
					ProductGroupId: this.groupId,
					ProductBrandId: this.brandId,
					SendType: this.seedType,
					OrderByType: this.orderByType,
					OrderByIsAsc: this.orderByIsAsc,
					Skip: (this.configPageIndex - 1) * this.configPageSize,
					Take: this.configPageSize
				})

				this.loading = false;

				if (res.IsSuccess) {
					this.productList = res.Result.Results;
					this.configTotal = res.Result.Total;
				}
			},
			async getGroupList() {
				const res = await productGroupList();

				if (res.IsSuccess) {
					this.groupList = res.Result;
				}
			},
			async getBrandList() {
				const res = await productBrandList();

				if (res.IsSuccess) {
					this.brandList = res.Result;
				}
			},
			async getSpecList(id) {
				const res = await productspecinfo({
					ProductId: id,
					ShopId: this.id
				});

				if (res.IsSuccess) {
					console.log(res, '88888888')
					// res.Result
					this.specList = res.Result.ProductSpecInfoDtos;
					this.SpecTitle = res.Result.SpecTitle
					this.SpecTitle2 = res.Result.SpecTitle2
					if (this.specList.length == 1 && this.specList[0].SpecValue == '') {
						this.ruleForm.singleprice = this.specList[0].SupplyPrice
					}
				}
			},
			handleConfigSizeChange(val) {
				this.configPageSize = val;
				this.getList();
			},
			handleConfigPageChange(val) {
				this.configPageIndex = val;
				this.getList();
			},
			goodsFilter() {
				this.configPageIndex = 1;
				this.getList();
			},
			keywordsTypeChange(val) {
				if (this.configSearchKey) {
					this.saleType = val
				}
				if (this.keywordsType == 0) {
					this.saleType = 0;
				} else {
					this.saleType = 1
				}
			},
			saleTypeChange(val) {
				if (this.configSearchKey) {
					if (val === 0 || val === 1)
						this.keywordsType = val
				}
				if (this.saleType == 0) {
					this.keywordsType = 0
				} else if (this.saleType == 1) {
					this.keywordsType = 1
				}
			},
			sortChange(obj) {

				this.orderByType = obj.prop == 'Stock' ? 1 : 0;
				if (!obj.order) {
					this.orderByIsAsc = null;
				} else {
					this.orderByIsAsc = obj.order === 'ascending';
				}

				this.configPageIndex = 1;
				this.getList();
			},
			async seedTypeChange(record, type) {
				try {
					const res = await shopproductsendtypeedit({
						ShopId: this.id,
						Id: record.Id,
						SendType: type
					})
				} catch (e) {

				} finally {
					this.getList();
				}
			},
			handleCommand(val) {

				if (!this.multipleSelection.length) {
					this.$message({showClose: true,
						type: 'error',
						message: '请选择商品'
					})
					return;
				}

// 				if (this.multipleSelection.some(item => item.ProductType == 1)) {
// 					this.$message({showClose: true,
// 						type: 'error',
// 						message: '选中的商品包含组合商品，组合商品不支持修改配送方式，请重新选择'
// 					})
// 					return;
// 				}
				this.emailData[0].email = false
				this.emailData[0].self = false
				this.isCurrentPage = true;
				this.emailVisble = true;

			},
			saveSeedType() {
				var obj = this.emailData[0];
				if (!obj.email && !obj.self && !obj.city) {
					this.$message({showClose: true,
						type: 'error',
						message: '商品配送方式请至少开启1个'
					})
					return;
				} else {
					this.stopDialogShow = true
				}
			},
			savePriceChange() {
				//如果是修改供货价
				if (this.stockVisble) {
					//如果是默认规格
					if (this.isNormalSpec) {
						console.log(this.ruleForm.singleprice, '供应价6666')
						if (!this.ruleForm.singleprice && typeof(this.ruleForm.singleprice) == 'string') {
							this.$message({showClose: true,
								type: 'error',
								message: '请完善供货价'
							})
							return
						}
						if (this.ruleForm.singleprice > 100000000) {
							this.$message({showClose: true,
								type: 'error',
								message: '供货价不可超过100000000'
							})
							return
						}
						this.stopActive(1)
					} else {
						// var list = this.specList.filter(item => {
						// 	return item.SupplyPrice>0 || (item.SupplyPrice== 0&&typeof(item.SupplyPrice)=='number');
						// })
						// console.log(list,'编辑供应价')
						var list = this.specList.filter(item => {
							return item.SupplyPrice == '' && typeof(item.SupplyPrice) == 'string';
						})
						var limitlist = this.specList.filter(item => {
							return item.SupplyPrice > 100000000;
						})
						if (list.length > 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '请完善供货价'
							})
							return
						}
						if (limitlist.length > 0) {
							this.$message({showClose: true,
								type: 'error',
								message: '供货价不可超过100000000'
							})
							return
						}

						this.stopActive(1)

					}
				}
			},
			async sureSavePrice() {
				try {
					this.loading = true
					let params = {
						IsAllShop: false,
						ProductId: this.productInfo.ProductId,
						ProductSpecSupplyList: this.specList.map(item => {
							return {
								ProductSpecId: item.Id,
								SupplyPrice: this.isNormalSpec ? this.ruleForm.singleprice : item.SupplyPrice
							}
						}),
						ShopIds: [this.id]
					}


					const res = await setshopproductspecsupplyprice(params);

					if (res.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '设置成功'
						})
						this.stopDialogShow = false;
						this.stockVisble = false;
						this.emailVisble = false;
						// this.multipleSelection = []
						this.getList();
					}
				} catch (err) {
					this.loading = false
					this.multipleSelection = []
				} finally {
					this.loading = false
				}


			},

			async sureSaveSeedType() {
				try {
					this.loading = true
					const res = await shopproductsendtypebatchedit({
						ShopProductConfigIds: this.multipleSelection.map(item => {
							return item.Id
						}),
						IsOpenSelfExtract: this.emailData[0].self,
						IsOpenExpress: this.emailData[0].email,
						IsOpenInCityDelivery:this.emailData[0].city,
					})
					if (res.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: res.Message
						})
						this.stopDialogShow = false;
						this.stockVisble = false;
						this.emailVisble = false;
						// this.multipleSelection = []
						this.getList();
					}
				} catch (err) {
					// this.$message({showClose: true,
					// 	type: 'error',
					// 	message: res.Message
					// })
					this.loading = false
					// this.multipleSelection = []
				} finally {
					this.loading = false
				}

			},

			productSelectChange(val) {

				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.productList.length ? true : false

				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}

			},
			tabCheckedChange() {
				this.$refs['doorChose'].toggleAllSelection();
			},
			//先去掉
// 			showTip() {
// 				this.$message({showClose: true,
// 					type: 'error',
// 					message: '组合商品不可以修改配送方式'
// 				})
// 			},

			eidtPrice(row) {
				this.ProductType = row.ProductType

				this.getSpecList(row.ProductId);

				this.productInfo = row;

				this.isCurrentPage = true;
				this.multipleSelection = [row];
				this.stockVisble = true
			},
			stopBtn() {
				this.stopDialogShow = true
			},
			stopActive(type) {
				if (type === 0) {
					console.log('取消喽', this.multipleSelection)
					this.stopDialogShow = false
					// this.multipleSelection = []
				} else {
					if (this.stockVisble) {
						this.sureSavePrice();
					} else {
						this.sureSaveSeedType();
					}
				}
			},
		}
	}
</script>

<style>
	.el-switch {
		line-height: 10px !important;
	}
	.columnClass .cell{
		/* border:1px solid black; */
		display: flex;
		position: relative;
	}
	.columnClass .cell .caret-wrapper{
		position: absolute;
		top: -5px;
		left: 60px;
	}
</style>
<style lang = "less" scoped>
.flexRow{
	display: flex;
	align-items: center;
}
.hoverShowIcon{
	display: none;
}
.hoverShow:hover{
	.hoverShowIcon{
		display: block !important;
	}
}
	.dialogpad ::v-deep .el-dialog__body{
		padding: 10px 20px 20px 20px;
	}
	.havestart ::v-deep .el-form-item__label::before{
		content: '*';
		color: #f56c6c;
		margin-right: 4px;
	}
</style>
